/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { shareReplayLastWithRefCount } from '@app/common/rxjs-operators/share-replay-last-with-ref-count';
import md5 from 'md5';
import { switchMap } from 'rxjs';
import { ShowForTargetDirective } from '../../modules/ggcc-target/show-for-target.directive';
import { ImageLoader } from './image-loader.service';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, ShowForTargetDirective, NgTemplateOutlet, AsyncPipe],
})
export class AvatarComponent {
  private imageLoader = inject(ImageLoader);

  public size = input<number>(40);
  public email = input.required<string>();

  public image$ = toObservable(this.email).pipe(
    switchMap((email) => this.imageLoader.loadImage(`https://www.gravatar.com/avatar/${md5(email)}?d=404`)),
    shareReplayLastWithRefCount,
  );
}
