<!-- Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/ -->

<ng-template #fallback>
  <mat-icon
    [style.fontSize.px]="size()"
    [style.width.px]="size()"
    [style.height.px]="size()"
    [style.lineHeight.px]="size()"
    gta="avatar__fallback"
  >
    account_circle
  </mat-icon>
</ng-template>
<ng-container *showForTarget="'on-premise'">
  <ng-container *ngTemplateOutlet="fallback"></ng-container>
</ng-container>
<ng-container *showForTarget="'hosted'">
  @if (image$ | async; as image) {
    @if (image.src) {
      <img
        [style.--mdc-icon-button-icon-size.px]="size()"
        [attr.src]="image.src"
        [width]="size()"
        [height]="size()"
        class="visible"
        gta="avatar__gravatar"
      />
    } @else {
      <mat-icon
        [style.fontSize.px]="size()"
        [style.width.px]="size()"
        [style.height.px]="size()"
        [style.lineHeight.px]="size()"
        gta="avatar__fallback"
      >
        account_circle
      </mat-icon>
    }
  } @else {
    <mat-icon
      [style.fontSize.px]="size()"
      [style.width.px]="size()"
      [style.height.px]="size()"
      [style.lineHeight.px]="size()"
      gta="avatar__fallback"
    >
      account_circle
    </mat-icon>
  }
</ng-container>
